import { HonorsClubData } from './honors-club';
import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import { HonorsClubControllerService } from '@/__generated__/FrontApi';
import arrowNext from '@/assets/img/icons/ico_pagination_next-wht@2x.png';
import arrowPrev from '@/assets/img/icons/ico_pagination_prev-wht@2x.png';
import donateCompany1 from '@/assets/img/tmp/legacy/donate_company_logo01@2x.png';
import donateCompany1M from '@/assets/img/tmp/legacy/donate_company_logo01M@2x.png';
import donateCompany2 from '@/assets/img/tmp/legacy/donate_company_logo02@2x.png';
import donateCompany2M from '@/assets/img/tmp/legacy/donate_company_logo02M@2x.png';
import donateCompany3 from '@/assets/img/tmp/legacy/donate_company_logo03@2x.png';
import donateCompany3M from '@/assets/img/tmp/legacy/donate_company_logo03M@2x.png';
import donateCompany4 from '@/assets/img/tmp/legacy/donate_company_logo04@2x.png';
import donateCompany4M from '@/assets/img/tmp/legacy/donate_company_logo04M@2x.png';
import donateCompany5 from '@/assets/img/tmp/legacy/donate_company_logo05@2x.png';
import donateCompany5M from '@/assets/img/tmp/legacy/donate_company_logo05M@2x.png';
import LegacyBn1 from '@/assets/img/tmp/legacy/legacy_bn1@2x.jpg';
import LegacyBn1M from '@/assets/img/tmp/legacy/legacy_bn1M@2x.jpg';
import HowImg1 from '@/assets/img/tmp/legacy/legacy_card_img1@2x.png';
import HowImg2 from '@/assets/img/tmp/legacy/legacy_card_img2@2x.png';
import HowImg3 from '@/assets/img/tmp/legacy/legacy_card_img3@2x.png';
import HowObj1 from '@/assets/img/tmp/legacy/legacy_card_obj1@2x.png';
import HowObj2 from '@/assets/img/tmp/legacy/legacy_card_obj2@2x.png';
import HowObj3 from '@/assets/img/tmp/legacy/legacy_card_obj3@2x.png';
import HonorBg from '@/assets/img/tmp/legacy/legacy_honor_bg.jpg';
import HonorBgM from '@/assets/img/tmp/legacy/legacy_honor_bgM@2x.jpg';
import HonorNameBoxL from '@/assets/img/tmp/legacy/legacy_namebox_left@2x.png';
import HonorNameBoxR from '@/assets/img/tmp/legacy/legacy_namebox_right@2x.png';
import treatObj1 from '@/assets/img/tmp/legacy/legacy_treat_obj1.png';
import treatObj2 from '@/assets/img/tmp/legacy/legacy_treat_obj2.png';
import treatObj3 from '@/assets/img/tmp/legacy/legacy_treat_obj3.png';
import treatObj4 from '@/assets/img/tmp/legacy/legacy_treat_obj4.png';
import treatObj5 from '@/assets/img/tmp/legacy/legacy_treat_obj5.png';
import treatObj6 from '@/assets/img/tmp/legacy/legacy_treat_obj6.png';
import memorialCard1 from '@/assets/img/tmp/legacy/memorial_card_img1.jpg';
import memorialCard2 from '@/assets/img/tmp/legacy/memorial_card_img2.jpg';
import memorialCard3 from '@/assets/img/tmp/legacy/memorial_card_img3.jpg';
import memorialCard4 from '@/assets/img/tmp/legacy/memorial_card_img4.jpg';
import AccordionComponent, { AccordionItem } from '@/components/Accordion';
import Button from '@/components/Button';
import { GtmButtonDonate } from '@/components/Button.gtm';
import ButtonMoreView from '@/components/ButtonMoreView';
import ContactUs from '@/components/ContactUs';
import Container from '@/components/Container';
import ErrorMessage from '@/components/Form/ErrorMessage';
import FormGroup from '@/components/Form/FormGroup';
import LabelText from '@/components/Form/LabelText';
import Image from '@/components/Image';
import InputCheckbox from '@/components/Input/InputCheckbox';
import InputNumber from '@/components/Input/InputNumber';
import InputRadio, { InputRadioGroup } from '@/components/Input/InputRadio';
import InputText from '@/components/Input/InputText';
import LayerPopup from '@/components/LayerPopup';
import LegacyLayerPopup from '@/components/LegacyLayerPopup';
import TabPanel, { TabData } from '@/components/LegacyTab';
import LinkSafe from '@/components/LinkSafe';
import { SectionTop } from '@/components/Nodiscrimination';
import PageComponent from '@/components/PageComponent';
import { ProcessList } from '@/components/ProcessList';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import SwiperBasic from '@/components/Swiper/SwiperBasic';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { SelectItemDefaultData } from '@/helpers/GtmHelper';
import { SUPPORT_TERM_ONCE } from '@/helpers/SupportHelper';
import { emailRegex, mobileRegex } from '@/helpers/ValidationHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { usePopupStore } from '@/stores/PopupStore';
import PageProps from 'gatsby';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

const tab: TabData[] = [
  {
    id: 1,
    subject: `나눔의 기쁨 편`,
    youtube: `u48krgIx0B8`,
  },
  {
    id: 2,
    subject: `나비효과 편`,
    youtube: `SGSYiCaUmwg`,
  },
  {
    id: 3,
    subject: `정갑영 회장 편`,
    youtube: `_OCCwMA4ubs`,
  },
  {
    id: 4,
    subject: `인생기부 편`,
    youtube: `GcVjrgjgv7M`,
  },
  {
    id: 5,
    subject: `3일 노트 편`,
    youtube: `df-dsmOS_Fc`,
  },
];
const tab2: TabData[] = [
  {
    id: 1,
    subject: `추모편지 편`,
    youtube: `iJSyE6NHO80`,
  },
];
const howData = [
  {
    id: 1,
    obj: HowObj1,
    tit: `<strong>유언공정증서</strong>에 의한 유산기부`,
    dec: `기부자 (유언자)가 증인 2명이 참석한 가운데 공증인 앞에서 유언 내용을 말하고, 공증인이 이 같은 유언 내용을 공정증서에 기록하는 방법`,
    img: HowImg1,
    review: `"지금 살고있는 아늑한 내 집이 언젠가는 저 어린이들에게 도움이 되기를 바라며, '내 집을 유니세프에 기부한다'는 내용을 유언장에 적어 두었습니다."`,
    writer: `김일두 후원자`,
    link: `/`,
  },
  {
    id: 2,
    obj: HowObj2,
    tit: `<strong>신탁</strong>에 의한 유산기부`,
    dec: `유언자가 금융회사와 자산신탁계약을 맺고 자산관리를 위탁하면서 사망 후 자산의 전부나 일부를 받게 될 수익자를 유니세프로 지정하는 방법`,
    img: HowImg2,
    review: `2018년 유니세프한국위원회와 신영증권이 유산기부 활성화 업무협약을 맺은 이후 유언대용신탁을 활용한 유산기부 약정의 첫 후원자입니다.`,
    writer: `이서인 후원자`,
    link: `/`,
  },
  {
    id: 3,
    obj: HowObj3,
    tit: `<strong>보험</strong>에 의한 유산기부`,
    dec: `사망 보험금의 수익자를 유니세프로 지정하는 기부 보험에 가입하거나, 종신보험 수익자를 유니세프로 변경하여 이전과 동일하게 보험료를 납입하다가 본인 사후에 발생하는 사망 보험금을 기부하는 방법`,
    img: HowImg3,
    review: `"나보다 더 오래 남을 기관에 내가 가진 것의 일부라도 맡겨 어느 곳에서든 도움이 절실한 아이들을 돕기를 바라는 마음으로 보험기부를 결정했습니다."`,
    writer: `이서영 후원자`,
    link: `/`,
  },
];
const processData = [
  {
    id: 1,
    tit: `유니세프
    담당자 상담`,
    sub: `(전화/방문)`,
  },
  {
    id: 2,
    tit: `전문기관
    자문 연계`,
  },
  {
    id: 3,
    tit: `유산기부 서약`,
    sub: `(서약식)`,
  },
  {
    id: 4,
    tit: `유산기부
    완료`,
  },
];
const treatData = [
  {
    id: 1,
    img: treatObj1,
    title: `유산기부 서약식`,
    desc: [
      {
        dec: `후원자에 대한 감사의 마음을 담아 
        서약식을 진행합니다.`,
      },
    ],
  },
  {
    id: 2,
    img: treatObj2,
    title: `후원자 이야기 홍보`,
    desc: [
      {
        dec: `내외부 홍보채널을 활용하여 후원자의 
        이야기를 홍보합니다.<br>
        (홈페이지, 뉴스레터, SNS 등)`,
      },
    ],
  },
  {
    id: 3,
    img: treatObj3,
    title: `유니세프 행사 초청`,
    desc: [
      {
        dec: `유니세프가 주최하는 행사에 후원자 및 
        가족이 참여할 수 있는 기회를 제공합니다.`,
      },
    ],
  },
  {
    id: 4,
    img: treatObj4,
    title: `기부증서/감사패 제공`,
    desc: [
      {
        dec: `숭고한 나눔을 실천해 주신 후원자님께 
        기부증서를 전달 드립니다.`,
      },
    ],
  },
  {
    id: 5,
    img: treatObj5,
    title: `명예의 전당 등재`,
    desc: [
      {
        dec: `유니세프 사옥 내 명예의 전당, 
        홈페이지에 회원으로 등재합니다.`,
      },
    ],
  },
  {
    id: 6,
    img: treatObj6,
    title: `원스톱 서비스 제공`,
    desc: [
      {
        dec: `법률, 금융, 세무, 장례상담 등 
        유산기부 전문가 그룹을 구성하여 
        신뢰 있는 서비스를 제공합니다.`,
      },
    ],
  },
];
const legacyData = [
  // type: pledger (약정자) / donor (기부자)
  {
    id: `legacy1`,
    type: `pledger`,
    name: `채명호`,
    pledgeDate: `2018년 08월 06일`,
  },
  {
    id: `legacy2`,
    type: `pledger`,
    name: `정기영`,
    pledgeDate: `2019년 11월 28일`,
  },
  {
    id: `legacy3`,
    type: `pledger`,
    name: `박진경`,
    pledgeDate: `2023년 12월 11일`,
  },
  {
    id: `legacy4`,
    type: `pledger`,
    name: `이서영`,
    pledgeDate: `2019년 08월 22일`,
  },
  // donor (기부자) 예시
  // {
  //   id: `legacy5`,
  //   type: `donor`,
  //   name: `故아무개5`,
  //   pledgeDate: `2000년`,
  //   lifespan: `1900.00.00~2000.11.11`,
  // },
];
const memorialData = [
  {
    id: `memorial1`,
    name: `故김태연,<br /> 故이향님의 가족`,
    message: `
      사랑하는 아내와 눈에 넣어도 아프지 않은 딸을 너무나
      갑작스럽게 잃었습니다. 나중에 크면 유니세프 같은 곳에서
      일하고 싶다던 딸 태연이를, 저는 오래오래 기억하고 싶습니다.
    `,
    fontSize: `small`,
  },
];
const contactData = [
  {
    id: 1,
    tit: `문의`,
    costumer: `고액후원팀`,
    tel: `02-721-5156`,
    email: `legacy@unicef.or.kr`,
  },
];

const HeadDec = styled.p`
  ${breakpoint(`mobile`)} {
    br {
      display: none;
    }
  }
`;

const HowCard = styled.div`
  .card {
    border: 1px solid #b7b8ba;
    margin-bottom: 64px;
    padding: 64px;

    &:last-child {
      margin-bottom: 0;
    }

    .img-box {
      padding-top: 100%;
    }

    .card-top {
      .col-img {
        width: 80px;
        padding: 0;
      }

      .col-dec {
        width: calc(100% - 80px);
        padding-left: 32px;

        ${Tit} {
          display: block;
          margin-bottom: 16px;
          strong {
            color: #1cabe2;
          }
        }
      }
    }

    .card-btt {
      margin-top: 50px;
      background: #f8f9fa;
      padding: 48px 84px;
      position: relative;

      .col-img {
        width: 160px;
      }

      .col-dec {
        width: calc(100% - 160px);
        padding-left: 64px;

        .writer {
          margin-top: 24px;

          &::before {
            content: &lsquo;-&rsquo;;
            display: inline-block;
            margin-right: 3px;
          }
        }
      }

      ${ButtonMoreView} {
        width: 100%;
        max-width: 256px;
        position: absolute;
        right: 0;
        bottom: -28px;
      }
    }

    ${breakpoint(1160)} {
      padding: 5.52vw;

      .card-btt {
        padding: 4.14vw 7.24vw;
      }
    }

    ${breakpoint(`tablet`)} {
      padding: 48px 24px;

      .card-btt {
        margin-top: 32px;
        padding: 32px 16px;
        ${ButtonMoreView} {
          max-width: 148px;
        }
      }
    }

    ${breakpoint(`mobile`)} {
      .card-top {
        flex-wrap: wrap;
        justify-content: center;

        .col-dec {
          width: 100%;
          margin-top: 8px;
          padding: 0;

          ${Tit} {
            text-align: center;
          }
        }
      }
      .card-btt {
        flex-wrap: wrap;
        justify-content: center;

        .col-dec {
          width: 100%;
          margin-top: 32px;
          padding: 0;
        }
      }
    }
  }
`;

const SectionInfo = styled(Section)`
  ${SectionHeader} {
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${Button} {
      width: 352px;
    }
    ${breakpoint(`tablet`)} {
      margin-left: 0;
      margin-right: 0;
      h2 {
        padding: 0;
      }
      ${Button} {
        width: 100%;
        margin-top: 57px;
      }
    }
    ${breakpoint(`mobile`)} {
      display: block;
    }
  }
`;
const BnContainer = styled(Section)`
  img {
    width: 100%;
  }
`;
const MemorialList = styled.ul`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 96px;

  ${breakpoint(`mobile`)} {
    margin-top: 60px;
  }
`;
const MemorialCard = styled.li`
  width: calc(50% - 16px);

  &:nth-child(n + 1):nth-child(-n + 2) {
    margin-bottom: 56px;
  }

  .title {
    font-size: 1.2rem;
    margin: 32px 32px 16px;
    font-weight: 600;
  }

  .description {
    margin: 0 32px;
  }

  .donator {
    margin-top: 8px;
    text-align: right;
  }

  ${breakpoint(`mobile`)} {
    width: 100%;
    margin-bottom: 24px;

    &:nth-child(n + 1):nth-child(-n + 2) {
      margin-bottom: 24px;
    }
    &:last-child {
      margin-bottom: 0;
    }

    .title {
      font-size: 1.2rem;
      margin: 16px 0;
    }

    .description {
      margin: 0;
    }
  }
`;

const SectionFaq = styled(Section)``;
const NavigationButton = styled.div`
  padding: 24px 48px 24px 24px;
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
  position: relative;

  ${breakpoint(640)} {
    font-size: 16px;
    padding: 20px 40px 20px 0;
  }
`;
const AccordionBody = styled.div`
  padding: 24px;
  font-size: 16px;
  color: #2d2926;
  ${breakpoint(640)} {
    font-size: 14px;
    padding: 16px 0;
  }
  // blut css 보정
  ul,
  ol,
  li {
    list-style: inherit;
    list-style-position: inside;
    list-style-type: inherit;
  }
  ol {
    list-style-type: demical;
  }
  ul {
    list-style-type: disc;
  }
  a {
    color: #1cabe2;
  }
`;

const SectionContact = styled(Section)`
  background: #f8f9fa;
  padding-bottom: 0 !important;
  margin-bottom: 120px;

  ${Container} {
    padding-bottom: 120px;

    ${SectionHeader} {
      p {
        margin-top: 10px;
      }
    }
  }
  .form-container {
    max-width: 736px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    ${FormGroup} {
      width: 50%;
      padding: 0 16px;
      margin-bottom: 24px;
      &.full {
        width: 100%;
      }
    }

    ${InputRadioGroup} {
      line-height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .chk-label {
      font-size: 14px;
    }

    ${breakpoint(`tablet`)} {
      ${FormGroup} {
        width: 100%;
        padding: 0;
      }
    }
  }

  .btn-wrap {
    margin-top: 78px;
    text-align: center;

    .btn {
      width: 100%;
      max-width: 350px;
    }
  }

  .clear {
    display: block;
    width: 100%;
    height: 0;
    clear: both;
    visibility: hidden;
  }

  .tip {
    background: #e2f0f6;
    line-height: 1.2;
    padding: 19px 0;
    text-align: center;
    font-size: 14px;

    p {
      max-width: 11600px;
      padding: 0 20px;
      margin: 0 auto;
      color: #1cabe2;
    }
  }

  ${breakpoint(`tablet`)} {
    margin-bottom: 60px;
  }

  ${breakpoint(`mobile`)} {
    ${Container} {
      padding-bottom: 50px;
    }
  }
`;

const SectionProcess = styled(Section)`
  padding-bottom: 80px !important;

  ${SectionHeader} {
    padding-bottom: 60px;
  }
  .donate-process {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    margin-bottom: 60px;

    li {
      width: calc((100% - 120px) / 4);
      min-height: 164px;
      border-radius: 50px;
      background: #dff7fd;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: 700;
      color: #1cabe2;
      text-align: center;
      letter-spacing: -0.8px;
      white-space: pre-line;

      .sub {
        font-size: 16px;
        font-weight: 400;
        margin-top: 10px;
      }
    }
  }

  .donate-company-tit {
    font-weight: 700;
    color: #2d2926;
    margin-bottom: 16px;
    span {
      color: #1cabe2;
    }
  }
  .donate-company-list {
    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
    }
    .donate-company-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: calc(100% / 5);
      height: 130px;
      border: 1px solid #d8d1c9;
      color: #2d2926;
      .desc {
        font-size: 14px;
        text-align: center;
        margin-top: 10px;
      }
    }
  }
  .text-tip {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.7;
    letter-spacing: -0.8px;
    color: #2d2926;
    margin-top: 16px;
  }

  ${breakpoint(`tablet`)} {
    .donate-company-list {
      ul {
        gap: 10px;
      }
      .donate-company-box {
        padding: 0 10px;
      }
    }
  }

  ${breakpoint(`mobile`)} {
    padding-bottom: 30px !important;

    ${SectionHeader} {
      padding-bottom: 30px;
    }
    .donate-process {
      gap: 40px 25px;
      margin-bottom: 26px;

      li {
        width: calc(50% - 13px);
        min-height: 102px;
        border-radius: 20px;
        font-size: 16px;

        .sub {
          font-size: 14px;
          margin-top: 0;
        }
      }
    }

    .donate-company-tit {
      font-size: 16px;
    }

    .donate-company-list {
      overflow: auto;

      .donate-company-box {
        width: calc(50% - 8px);
        margin-right: 16px;
        font-size: 16px;
        text-align: center;
        padding: 0;
        &:last-of-type {
          margin-right: 0;
        }

        .desc {
          margin-top: 14px;
        }
      }
    }
    .text-tip {
      font-size: 14px;
      font-weight: 300;
      line-height: 1.7;
      letter-spacing: -0.8px;
      color: #2d2926;
      margin-top: 16px;
    }
  }
`;
const SwiperCustom = styled(SwiperBasic)`
  padding: 0;
  .swiper-container {
    padding-bottom: 20px !important;
  }
  .swiper-slide {
    border: 1px solid #d8d1c9;
    width: 200px;
    height: 130px;
  }
  .swiper-scrollbar {
    left: 0;
    right: 0;
  }
`;
const SectionTreat = styled(Section)`
  .process-ol {
    gap: 50px;
  }
  .list-item {
    &:nth-child(n + 1):nth-child(-n + 3) {
      margin-bottom: 60px;
    }

    dd h3 {
      margin-bottom: 0;
    }
  }
  ${breakpoint(`tablet`)} {
    .process-ol {
      gap: 0;
    }
    .list-item {
      margin-bottom: 48px !important;
      &:nth-child(n + 1):nth-child(-n + 3) {
        margin-bottom: 48px;
      }
      &:nth-last-child(n + 1):nth-last-child(-n + 2) {
        margin-bottom: 0 !important;
      }
    }
  }
`;
const SectionHonor = styled(Section)`
  .section-honor-wrap {
    min-height: 533px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: url(${HonorBg}) no-repeat 50% 0;
    background-size: cover;
    padding: 90px 40px 80px;
    text-align: center;
    color: #fff;

    .br_m {
      display: none;
    }
    h2 {
      text-align: center;
      ${Tit} {
        font-family: 'GangwonEduPowerExtraBoldA';
        line-height: 0.8;
      }
    }
    .honor-tit__sub {
      font-size: 24px;
      font-weight: 700;
      color: #a3e5ff;
    }
    .honor-text {
      font-size: 16px;
      font-weight: 700;
      margin-top: 20px;
    }
    ${InputRadioGroup} {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 20px;
      margin-top: 40px;

      label {
        height: 31px;
        line-height: 27px;
      }

      .all {
        min-width: 60px;

        input[type='radio']:checked + .btn {
          background: transparent;
          color: #fff;
        }
        .btn {
          height: 31px;
          line-height: 29px;
        }
        .txt {
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
    .honor-lists {
      padding-top: 20px;

      ul {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 20px 22.5px;

        li {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 190px;
          min-height: 72px;
          height: fit-content;
          border-radius: 4px;
          background: #1cabe2;
          position: relative;
          padding: 0 25px;
          cursor: pointer;

          &::before,
          &::after {
            content: '';
            display: block;
            width: 27px;
            height: 45px;
            position: absolute;
            top: 50%;
            transform: translateY(calc(-50% + 2px));
          }
          &::before {
            background: url(${HonorNameBoxL}) no-repeat 50% 50%;
            background-size: contain;
            left: 8px;
          }
          &::after {
            background: url(${HonorNameBoxR}) no-repeat 50% 50%;
            background-size: contain;
            right: 8px;
          }
          span {
            position: relative;
            z-index: 1;
            font-size: 20px;
            font-weight: 700;

            &.fz-small {
              font-size: 18px;
            }
          }
        }
      }
    }
    .pagination {
      margin-top: 30px;
      .pagination-item {
        padding: 0 10px;

        &.active {
          font-weight: bold;
        }
      }

      .pagination-arrow {
        width: 20px;
        height: 20px;
        border: 1px solid #fff;
        font-size: 0;
        background-repeat: no-repeat;
        background-size: 6px 12px;
        background-position: 50% 50%;

        &.pagination-prev {
          margin-right: 10px;
          background-image: url(${arrowPrev});
        }
        &.pagination-next {
          margin-left: 10px;
          background-image: url(${arrowNext});
        }
      }
    }
  }

  ${breakpoint(`mobile`)} {
    ${Container} {
      padding: 0;
    }
    .section-honor-wrap {
      min-height: 567px;
      background: url(${HonorBgM}) no-repeat 50% 0;
      background-size: cover;
      padding: 50px 35px 70px;

      .br_pc {
        display: none;
      }
      .br_m {
        display: block;
      }

      .honor-tit__sub {
        font-size: 16px;
      }
      .honor-text {
        font-size: 14px;
        font-weight: 400;
      }

      ${InputRadioGroup} {
        justify-content: center;
        margin-top: 30px;
      }
      .honor-lists {
        padding-top: 16px;
        min-height: 222px;
        ul {
          gap: 16px;
          li {
            width: calc(50% - 8px);
            min-height: 58px;
            padding: 0 15px;
            &::before,
            &::after {
              width: 20px;
              height: 33px;
            }
            &::before {
              left: 4px;
            }
            &::after {
              right: 4px;
            }

            span {
              font-size: 16px;
              &.fz-small {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
`;

export interface FaqData {
  id: string | number;
  title?: string;
  contents?: string;
}

interface PopupData {
  PopupSid?: string | number;
}

const Legacy: React.FC<PageProps> = ({ location }) => {
  const [faqLists, setFaqLists] = useState<FaqData[]>([]);

  const loadFaqLists = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['260'],
        optData1: '1',
        optData2: '106',
        pagePerCount: 6,
        statusCode: '1',
      });

      const legacyFaq = data as any;
      setFaqLists(
        legacyFaq.map((faq: BoardVo) => ({
          id: faq.boardIndexNumber,
          title: faq.subject,
          contents: faq.contents,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadFaqLists();
  }, [loadFaqLists]);

  // 명예의 전당 팝업
  const [popupOpen, setPopupOpen] = useState(false);
  const [PopupTitle, setPopupTitle] = useState(``);
  const [PopupSid, setPopupSid] = useState<PopupData | string | number>(``);
  const [isSwiperEnabled, setIsSwiperEnabled] = useState(true);

  const dataMapping = useMemo(
    () => ({
      유산기부: [...legacyData],
      추모기부: [...memorialData],
      전체: [...legacyData, ...memorialData],
    }),
    [],
  );

  const [selectedData, setSelectedData] = useState(dataMapping['유산기부']); // 유산기부·추모기부 선택
  const [selectedValue, setSelectedValue] = useState('전체');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);

  useEffect(() => {
    setItemsPerPage(window.innerWidth <= 640 ? 8 : 15);
  }, []);

  useEffect(() => {
    setSelectedData([...dataMapping['유산기부']]);
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    setSelectedData([...dataMapping[selectedValue]]);
    setCurrentPage(1);
  }, [selectedValue, dataMapping]);

  const totalPages = Math.ceil(selectedData.length / itemsPerPage);
  const showPagination = selectedData.length > itemsPerPage; // 데이터 개수에 따라 페이지네이션 여부 결정

  const pageNumbers = Array.from(
    { length: Math.min(5, totalPages) },
    (_, i) => Math.max(1, currentPage - 2) + i,
  );

  const handleRadioChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    setSelectedData([...dataMapping[newValue]]);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = Array.isArray(selectedData)
    ? selectedData.slice(startIndex, startIndex + itemsPerPage)
    : [];

  const handleOpenModal = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSwiperEnabled(window.innerWidth < 640); // 991px 미만에서만 Swiper 활성화
    };

    handleResize(); // 초기 실행
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const open = () => setPopupOpen(true);
  const close = () => setPopupOpen(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<HonorsClubData>({
    defaultValues: {
      userName: ``,
      userMobile: ``,
      userEmail: ``,
      agreeCheck: false,
      formType: `honors`,
      inquiryType: ``,
    },
  });
  const popupStore = usePopupStore();
  const onSubmit: SubmitHandler<HonorsClubData> = useCallback(
    async ({
      userName,
      userMobile,
      userEmail,
      agreeCheck,
      inquiryType,
      ...formData
    }) => {
      try {
        const {
          resultCode,
          resultMessage,
        } = await HonorsClubControllerService.insertHonorsQnaUsingPost({
          ...formData,
          name: userName,
          mobile: userMobile,
          email: userEmail,
          isAgreeYn: agreeCheck ? `Y` : `N`,
          type: `legacy`,
          inquiryType,
        });
        popupStore.show(resultMessage);
        reset();
      } catch {
        popupStore.show(`문의 등록에 실패했습니다. 다시 시도해주세요.`);
      }
    },
    [popupStore],
  );

  const LegacyDonationRef = useRef();
  const MemorialDonationRef = useRef();

  const scrollToLegacyDonation = () => {
    LegacyDonationRef.current.scrollIntoView({ behavior: `smooth` });
  };
  const scrollToMemorialDonation = () => {
    MemorialDonationRef.current.scrollIntoView({ behavior: `smooth` }, []);
  };

  return (
    <LayoutWithTitle
      location={location}
      title="유산-추모기부"
      description="for every child, legacy giving"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-wrap">
              <h2>
                <Tit size="s1">
                  <PageComponent id="title1">
                    내 인생을 영원히 빛내줄 고귀한 약속
                  </PageComponent>
                </Tit>
              </h2>
              <div className="col-btn">
                <ul className="flex">
                  <li>
                    <Button full onClick={scrollToLegacyDonation}>
                      유산기부
                    </Button>
                  </li>
                  <li>
                    <Button full onClick={scrollToMemorialDonation}>
                      추모기부
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
            <HeadDec className="head-dec">
              <PageComponent id="content1">
                나눔으로 기억되는 세상에서 가장 아름다운 기부,
                <br />전 세계 어린이의 더 좋은 세상, 더 나은 삶을 위해 함께해
                주세요
              </PageComponent>
            </HeadDec>
          </SectionHeader>
          <TabPanel tabs={tab} />
        </Container>
      </SectionTop>

      <Section ref={LegacyDonationRef}>
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">유산을 기부하는 방법</Tit>
            </h2>
          </SectionHeader>

          <HowCard>
            {howData.map((row) => (
              <div className="card" key={row.id}>
                <div className="card-top flex">
                  <div className="col-img">
                    <div
                      className="img-box"
                      css={`
                        background-image: url(${row.obj});
                      `}
                    />
                  </div>

                  <dl className="col-dec">
                    <dt>
                      <Tit
                        size="s4"
                        dangerouslySetInnerHTML={{ __html: row.tit }}
                      />
                    </dt>
                    <dd>{row.dec}</dd>
                  </dl>
                </div>

                <div className="card-btt flex">
                  <div className="col-img">
                    <div
                      className="img-box"
                      css={`
                        background-image: url(${row.img});
                      `}
                    />
                  </div>

                  <div className="col-dec">
                    <Tit size="s4">{row.review}</Tit>
                    <p className="writer">{row.writer}</p>
                  </div>
                  <div>
                    <ButtonMoreView
                      mode="sky"
                      onClick={() => {
                        open();
                        setPopupTitle(row.tit);
                        setPopupSid(row.id);
                      }}
                    >
                      자세히 보기
                    </ButtonMoreView>
                  </div>
                </div>
              </div>
            ))}
            <LegacyLayerPopup
              isOpen={popupOpen}
              PopTitle={PopupTitle}
              PopSid={PopupSid}
              onRequestClose={close}
            />
          </HowCard>
        </Container>
      </Section>

      <SectionProcess className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">유산기부 절차</Tit>
            </h2>
          </SectionHeader>
          <ul className="donate-process">
            {processData.map((row) => (
              <li>
                <div className="num">{row.id}</div>
                <div className="tit">{row.tit}</div>
                <div className="sub">{row?.sub}</div>
              </li>
            ))}
          </ul>
          <div className="donate-company">
            <p className="donate-company-tit">
              <span>*</span> 함께하는 기관
            </p>
            <div className="donate-company-list">
              {isSwiperEnabled ? (
                <SwiperCustom className="SwiperCustom" overflowHidden>
                  <Swiper
                    slidesPerView="auto"
                    navigation
                    scrollbar={{ draggable: true }}
                  >
                    <SwiperSlide className="donate-company-box">
                      <div className="logo">
                        <Image
                          pcSrc={donateCompany1}
                          mobileSrc={donateCompany1M}
                        />
                      </div>
                      <div className="desc">유언공증 증인 지원</div>
                    </SwiperSlide>
                    <SwiperSlide className="donate-company-box">
                      <div className="logo">
                        <Image
                          pcSrc={donateCompany2}
                          mobileSrc={donateCompany2M}
                        />
                      </div>
                      <div className="desc">법률자문 지원</div>
                    </SwiperSlide>
                    <SwiperSlide className="donate-company-box">
                      <div className="logo">
                        <Image
                          pcSrc={donateCompany3}
                          mobileSrc={donateCompany3M}
                        />
                      </div>
                      <div className="desc">유언대용신탁 자문 지원</div>
                    </SwiperSlide>
                    <SwiperSlide className="donate-company-box">
                      <div className="logo">
                        <Image
                          pcSrc={donateCompany4}
                          mobileSrc={donateCompany4M}
                        />
                      </div>
                      <div className="desc">생명보험 자문 지원</div>
                    </SwiperSlide>
                    <SwiperSlide className="donate-company-box">
                      <div className="logo">
                        <Image
                          pcSrc={donateCompany5}
                          mobileSrc={donateCompany5M}
                        />
                      </div>
                      <div className="desc">장례 상담 서비스 지원</div>
                    </SwiperSlide>
                  </Swiper>
                </SwiperCustom>
              ) : (
                <ul>
                  <li className="donate-company-box">
                    <div className="logo">
                      <Image
                        pcSrc={donateCompany1}
                        mobileSrc={donateCompany1M}
                      />
                    </div>
                    <div className="desc">유언공증 증인 지원</div>
                  </li>
                  <li className="donate-company-box">
                    <div className="logo">
                      <Image
                        pcSrc={donateCompany2}
                        mobileSrc={donateCompany2M}
                      />
                    </div>
                    <div className="desc">법률자문 지원</div>
                  </li>
                  <li className="donate-company-box">
                    <div className="logo">
                      <Image
                        pcSrc={donateCompany3}
                        mobileSrc={donateCompany3M}
                      />
                    </div>
                    <div className="desc">유언대용신탁 자문 지원</div>
                  </li>
                  <li className="donate-company-box">
                    <div className="logo">
                      <Image
                        pcSrc={donateCompany4}
                        mobileSrc={donateCompany4M}
                      />
                    </div>
                    <div className="desc">생명보험 자문 지원</div>
                  </li>
                  <li className="donate-company-box">
                    <div className="logo">
                      <Image
                        pcSrc={donateCompany5}
                        mobileSrc={donateCompany5M}
                      />
                    </div>
                    <div className="desc">장례 상담 서비스 지원</div>
                  </li>
                </ul>
              )}
            </div>

            <p className="text-tip">
              자세한 내용은 기부자의 후원 유형에 맞추어 유산기부 담당자가 안내
              드립니다.
            </p>
          </div>
        </Container>
      </SectionProcess>

      <SectionTreat>
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">유산기부 예우</Tit>
            </h2>
          </SectionHeader>
          <ProcessList itemData={treatData} />
        </Container>
      </SectionTreat>

      {/* <SectionInfo className="by-sub-main-layout">
        <Container>
          <SectionHeader
            className="with-desc"
            css={`
              ${breakpoint(640)} {
                display: block !important;
              }
            `}
          >
            <h2>
              <Tit size="s1">유산기부 안내자료</Tit>
            </h2>
            <div>
              <Button
                outline
                className="button"
                ico="down"
                onClick={() => {
                  open();
                  setPopupTitle(`유산기부 안내자료 다운로드`);
                  setPopupSid(4);
                }}
              >
                <span className="ico">다운로드</span>
              </Button>
            </div>
          </SectionHeader>
        </Container>
      </SectionInfo> */}

      <SectionHonor className="by-sub-main-layout">
        <Container>
          <div className="section-honor-wrap">
            <div className="honor_top">
              <h2>
                <Tit size="s1">유니세프 명예의 전당</Tit>
              </h2>
              <p className="honor-tit__sub">인생을 영원히 빛내 줄 고귀한 약속</p>
              <p className="honor-text">
                2006년부터 시작된 유니세프 유산기부에 함께해주신{' '}
                <br className="br_m" />
                후원자님들의
                <br className="br_pc" />
                소중한 나눔을 기억하며,
                <br className="br_m" /> 그 귀한 이름을 명예의 전당에 영원히
                새깁니다.
              </p>
            </div>

            <div className="honor_bottom">
              <InputRadioGroup>
                <InputRadio
                  tab
                  color="white"
                  outline
                  bg="transparent"
                  noHover
                  name="donateType"
                  ref={register}
                  className="all"
                  value="전체"
                  label="전체"
                  checked={selectedValue === '전체'}
                  onChange={handleRadioChange}
                />
                <InputRadio
                  color="white"
                  name="donateType"
                  ref={register}
                  value="유산기부"
                  label="유산기부"
                  checked={selectedValue === '유산기부'}
                  onChange={handleRadioChange}
                />
                <InputRadio
                  color="white"
                  name="donateType"
                  ref={register}
                  value="추모기부"
                  label="추모기부"
                  checked={selectedValue === '추모기부'}
                  onChange={handleRadioChange}
                />
              </InputRadioGroup>

              <div className="honor-lists">
                <ul>
                  {currentItems.map((row) => (
                    <li key={row.id} onClick={() => handleOpenModal(row)}>
                      <span
                        className={row.fontSize === 'small' ? 'fz-small' : ''}
                        dangerouslySetInnerHTML={{ __html: row.name }}
                      />
                    </li>
                  ))}
                </ul>
              </div>
              {showPagination && totalPages > 1 && (
                <div className="pagination">
                  <button
                    className="pagination-arrow pagination-prev"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    이전
                  </button>
                  {pageNumbers.map((number) => (
                    <button
                      key={number}
                      onClick={() => handlePageChange(number)}
                      className={
                        currentPage === number
                          ? 'pagination-item active'
                          : 'pagination-item'
                      }
                    >
                      {number}
                    </button>
                  ))}
                  <button
                    className="pagination-arrow pagination-next"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    다음
                  </button>
                </div>
                )}
            </div>

            {/* 모달 팝업 */}
            {selectedItem && (
              <LayerPopup
                isOpen={isModalOpen}
                title={
                  legacyData.some((item) => item.id === selectedItem.id)
                    ? selectedItem.type === 'pledger'
                      ? '유산 약정자'
                      : '유산 기부자'
                    : memorialData.some((item) => item.id === selectedItem.id)
                    ? '추모 기부자'
                    : ''
                }
                onRequestClose={() => setIsModalOpen(false)}
              >
                <div
                  css={`
                    line-height: 2;
                  `}
                >
                  <p>
                    {legacyData.some((item) => item.id === selectedItem.id) ? (
                      <>
                        <strong>{selectedItem.name}님 </strong>
                        {selectedItem.lifespan && (
                          <strong>&#40;{selectedItem.lifespan}&#41;</strong>
                        )}
                      </>
                    ) : (
                      <strong>
                        {selectedItem.name.replace('<br />', ' ')}
                      </strong>
                    )}
                  </p>

                  {/* selectedItem 따라 다른 내용 표시 */}
                  {legacyData.find((item) => item.id === selectedItem.id) ? (
                    selectedItem.type === 'pledger' ? (
                      <p>
                        {selectedItem.name}님은 {selectedItem.pledgeDate}에 전
                        세계 어린이들이 행복하게 자랄 수 있도록 유산기부로 귀한
                        약속을 해주셨습니다. <br />
                        귀하의 따뜻한 마음은 희망의 씨앗이 되어 미래 세대에 큰
                        변화를 만들어 갈 것입니다.
                      </p>
                    ) : (
                      <p>
                        {selectedItem.name}님은 {selectedItem.pledgeDate}에 전
                        세계 어린이들이 행복하게 자랄 수 있도록 희망을
                        전해주셨습니다. <br />
                        어린이를 위한 후원자님의 아름다운 사랑과 생애를 영원히
                        기억하겠습니다
                      </p>
                    )
                  ) : (
                    <p>
                      소중한 존재를 기억하는 가장 아름다운 선택에 함께해 주셔서
                      감사합니다. <br />
                      함께 나눈 소중했던 시간들이 전 세계 어린이들의 삶 속에서
                      영원히 빛날 것 입니다. <br />"{selectedItem.message}"
                    </p>
                  )}
                </div>
              </LayerPopup>
            )}
          </div>
        </Container>
      </SectionHonor>

      <SectionContact className="by-sub-main-layout">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Container>
            <SectionHeader className="with-desc">
              <h2>
                <Tit size="s1">유산기부 문의</Tit>
              </h2>
              <p>
                유산 기부에 대해 문의하시면, 등록된 연락처와 이메일로 유산 기부
                브로슈어를 자동으로 보내드립니다.
              </p>
            </SectionHeader>

            <div className="form-container">
              <FormGroup>
                <LabelText require>성명</LabelText>
                <InputText
                  placeholder="성명 입력"
                  name="userName"
                  ref={register({
                    required: {
                      value: true,
                      message: `성명을 입력해주세요.`,
                    },
                    maxLength: {
                      value: 50,
                      message: `50자 이내로 입력해주세요.`,
                    },
                  })}
                />
                {errors.userName && (
                  <ErrorMessage>{errors.userName.message}</ErrorMessage>
                )}
              </FormGroup>
              <FormGroup>
                <LabelText require>문의종류</LabelText>
                <InputRadioGroup>
                  <InputRadio
                    outline
                    name="inquiryType"
                    ref={register({
                      required: {
                        value: true,
                        message: `문의종류를 선택해주세요.`,
                      },
                    })}
                    value="유언공증"
                    label="유언공증"
                    bg="white"
                  />
                  <InputRadio
                    name="inquiryType"
                    ref={register}
                    value="신탁"
                    label="신탁"
                    bg="white"
                  />
                  <InputRadio
                    name="inquiryType"
                    ref={register}
                    value="보험"
                    label="보험"
                    bg="white"
                  />
                </InputRadioGroup>
                {errors.inquiryType && (
                  <ErrorMessage>{errors.inquiryType.message}</ErrorMessage>
                )}
              </FormGroup>
              <FormGroup>
                <LabelText require>연락처</LabelText>
                <Controller
                  control={control}
                  name="userMobile"
                  rules={{
                    required: {
                      value: true,
                      message: `연락처를 입력해주세요.`,
                    },
                    pattern: {
                      value: mobileRegex,
                      message: `올바른 연락처 형식이 아닙니다.`,
                    },
                    validate: {
                      isValidRange: (value) =>
                        (value.substr(0, 3) === `010` && value.length === 11) ||
                        (value.substr(0, 3) !== `010` && value.length === 10) ||
                        `휴대폰번호 자릿수를 확인해주세요`,
                    },
                  }}
                  as={
                    <InputNumber
                      format="###########"
                      placeholder="연락처 입력 ( &lsquo;-&rsquo; 제외 )"
                    />
                  }
                />
                {errors.userMobile && (
                  <ErrorMessage>{errors.userMobile.message}</ErrorMessage>
                )}
              </FormGroup>
              <FormGroup>
                <LabelText require>이메일</LabelText>
                <InputText
                  name="userEmail"
                  ref={register({
                    required: {
                      value: true,
                      message: `이메일을 입력해주세요.`,
                    },
                    pattern: {
                      value: emailRegex,
                      message: `올바른 이메일 형식이 아닙니다.`,
                    },
                    maxLength: {
                      value: 100,
                      message: `100자 이내로 입력해주세요.`,
                    },
                  })}
                  placeholder="이메일주소 입력"
                />
                {errors.userEmail && (
                  <ErrorMessage>{errors.userEmail.message}</ErrorMessage>
                )}
              </FormGroup>
              <FormGroup className="full">
                <InputCheckbox
                  className="chk-label"
                  name="agreeCheck"
                  label="예, 저는 유산기부 안내를 받고 싶습니다. 그리고 안내를 원치 않을 시, 언제든지 수신거부 할 수 있음을 잘 알고 있습니다."
                  ref={register({
                    required: {
                      value: true,
                      message: `약관 동의에 체크해주세요.`,
                    },
                  })}
                />
                {errors.agreeCheck && (
                  <ErrorMessage>{errors.agreeCheck.message}</ErrorMessage>
                )}
              </FormGroup>
            </div>
            <div className="btn-wrap">
              <div className="clear" />
              <Button className="btn" type="submit">
                문의하기
              </Button>
            </div>
          </Container>
        </form>
        <div className="tip">
          <p>
            법무법인 광장(LEE&#38;KO) 공익활동위원회의 &#39;유니세프
            법률지원팀&#39;이 유산기부에 필요한 법률자문을 제공합니다.
          </p>
        </div>
      </SectionContact>

      <BnContainer
        ref={MemorialDonationRef}
        style={{ scrollMarginTop: '200px' }}
      >
        <Container>
          <GtmButtonDonate
            type="button"
            text="후원하기"
            color="white"
            className="btn-support-legacy"
            goto="/donate/?supportTerm=FM02&supportCategory=D224&atype=09"
            gtmInfo={{
              ...SelectItemDefaultData,
              itemId: 'D224',
              itemName: '기념기부',
              itemCategory: SUPPORT_TERM_ONCE,
            }}
            onClickPreEvent={() => {
              // TODO onClick before call gtm
              // alert('click');
            }}
          >
            <Image pcSrc={LegacyBn1} mobileSrc={LegacyBn1M} />
          </GtmButtonDonate>
        </Container>
      </BnContainer>

      <Container>
        <div
          style={{
            marginTop: '60px',
            // lineHeight: 1.2,
            // letterSpacing: '1.2px',
          }}
        >
          {/* <Tit size="s1">
                  <PageComponent id="title1">
                    내 인생을 영원히 빛내줄 고귀한 약속
                  </PageComponent>
                </Tit> */}
          <Tit
            size="s1"
            // style={{
            //   fontSize: '1.5rem',
            // }}
          >
            추모기부는 소중한 존재를
            <br />
            기억하는 아름다운 선택입니다
          </Tit>
          <div style={{ marginTop: '32px', marginBottom: '60px' }}>
            사랑하는 이를 떠나 보낸 후 우리는 함께한 날들을 추억하며 그리움에
            젖습니다.
            <br />
            다시 만날 수는 없지만 잊지 않고 기억할 수 는 있습니다.
            <br />
            함께 나눈 소중했던 시간들이 전 세계 어린이들의 삶 속에서 영원히 빛날
            수 있도록 추모하는 마음을 모아 유니세프에 보내주세요.
            <br />
            여러분이 보내주신 사랑은 어린이의 생명과 희망이 되어 우리의 세상을
            오래도록 환하게 밝힐 것입니다.
          </div>
          <TabPanel tabs={tab2} />
        </div>
        <MemorialList>
          <MemorialCard>
            <Image pcSrc={memorialCard1} mobileSrc={memorialCard1} />
            <div className="title">가족 및 지인</div>
            <div className="description">
              “하늘에 있는 아내와 딸을 대신해 기부합니다.” <br />
              -매년 아내와 딸의 기일에 1천만 원을 꾸준히 기부하고 있는 김융기
              후원자
            </div>
          </MemorialCard>
          <MemorialCard>
            <Image pcSrc={memorialCard2} mobileSrc={memorialCard2} />
            <div className="title">반려동물</div>
            <div className="description">
              “올해 초 무지개다리를 건너게 된 우리 강아지의 사랑이 계속 이어지길
              소망하며 기부합니다.”
            </div>
          </MemorialCard>
          <MemorialCard>
            <Image pcSrc={memorialCard3} mobileSrc={memorialCard3} />
            <div className="title">존경하는 대상</div>
            <div className="description">
              “대중에게 아름다운 기억과 영감을 주고 간 나의 뮤즈를 추억하며
              기부합니다.”
            </div>
          </MemorialCard>
          <MemorialCard>
            <Image pcSrc={memorialCard4} mobileSrc={memorialCard4} />
            <div className="title">추모 기념일</div>
            <div className="description">
              “6월 호국보훈의 달을 맞이하여 애국선열의 숭고한 정신을 기리기 위해
              기부합니다.” <br />
              “11월 11일 UN 국제 추모의 날을 기념하여 전쟁으로 목숨을 잃은
              군인을 위해 기부합니다.”
            </div>
          </MemorialCard>
        </MemorialList>
      </Container>

      <SectionFaq className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">자주 묻는 질문</Tit>
              </h2>
              <LinkSafe
                to="/faq?category=1&optData2=106#categorySection"
                className="more"
              >
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {faqLists.length > 0 && (
            <AccordionComponent initialKey={faqLists[0].id}>
              {faqLists.map((faq) => (
                <AccordionItem
                  key={faq.id}
                  toggleKey={faq.id}
                  renderToggle={(onClick) => (
                    <NavigationButton
                      onClick={onClick}
                      className="accordion-opener"
                    >
                      {faq.title}
                    </NavigationButton>
                  )}
                >
                  <AccordionBody
                    dangerouslySetInnerHTML={{ __html: faq.contents }}
                  />
                </AccordionItem>
              ))}
            </AccordionComponent>
          )}
        </Container>
      </SectionFaq>

      <Section className="by-sub-main-layout">
        <Container>
          <ContactUs itemData={contactData} />
        </Container>
      </Section>
    </LayoutWithTitle>
  );
};

export default Legacy;
